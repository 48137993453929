import React, { useEffect, useState, useMemo } from "react";
// Imports from antd are done individually to minimize bundlesize
import Button from "antd/es/button";
import Select from "antd/es/select";
import Empty from "antd/es/empty";
import Tabs from "antd/es/tabs";
import Modal from "antd/es/modal";
import Input from "antd/es/input";
import Col from "antd/es/col";
import Card from "antd/es/card";
import Statistic from "antd/es/statistic";
import "./dashboard.css";
import { useForm, Controller } from "react-hook-form";
import { sortBy } from "underscore";
import Layout from "./Layout";

import { useParams, useHistory } from "react-router-dom";
import DOMPurify from "dompurify";
import Calendar from "./Calendar";
import ReservationsTable from "./ReservationsTable";
import CostTable from "./CostTable";
import SettlementRules from "./SettlementRules";
import RentalInfo from "./RentalInfo";
import { checkRole, Period } from "./utils";
import PeriodSelect from "./PeriodSelect";

const { REACT_APP_API_URL: API_URL } = process.env;

const axios = require("axios").default;

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const dayjs = require("dayjs");
const html2pdf = require("html2pdf.js");

dayjs().format();

export function logOut(handleUnauthorized = true) {
  if (handleUnauthorized) {
    // used when 401 error is returned from the API
    alert("Sesja wygasła.");
  }
  localStorage.clear();
  window.location.reload();
}

export function genericError() {
  // used when error other than 401 is returned from the API
  alert(
    "Wystąpił błąd podczas komunikacji z serwerem. Spróbuj ponownie za kilka minut."
  );
}

export default function Dashboard() {
  const {
    rentalId: pathRentalId,
    landlordId: pathLandlordId,
    yearFilter: pathYearFilter,
    monthFilter: pathMonthFilter,
  } = useParams();
  const history = useHistory();

  const selectedRental = pathRentalId || null;
  const selectedLandlord = pathLandlordId || null;

  const period = useMemo(
    () => Period.fromPath(pathMonthFilter, pathYearFilter, dayjs()),
    [pathMonthFilter, pathYearFilter]
  );

  const [rentalOwnerships, setRentalOwnerships] = useState([]);
  const [contract, setContract] = useState(null);
  const [landlords, setLandlords] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [columns, setColumns] = useState([]);
  const [costs, setCosts] = useState([]);
  const [settlement, setSettlement] = useState(null);
  const [rejectNoteVisible, setRejectNoteVisible] = useState(false);
  const [companyDataVisible, setCompanyDataVisible] = useState(false);
  const [invoiceAcceptNoteVisible, setInvoiceAcceptNoteVisible] =
    useState(false);
  const [faqContent, setFaqContent] = useState(null);
  const { control, errors, handleSubmit, reset } = useForm();
  const [isSettlementLoading, setIsSettlementLoading] = useState(false);

  const rentalOwnership =
    selectedRental && rentalOwnerships
      ? rentalOwnerships.find(
          (ro) =>
            ro.rental.bookingsync_rental_id === selectedRental &&
            ro.landlord.id === selectedLandlord
        )
      : null;

  const rentals = [
    ...new Set(
      rentalOwnerships && rentalOwnerships.map((ro) => ro.rental.name)
    ),
  ];

  const rental = rentalOwnership && rentalOwnership.rental;
  const landlord = rentalOwnership && rentalOwnership.landlord;
  const viewOnly = rentalOwnership ? rentalOwnership.view_only : true;
  const rentalShare = rentalOwnership ? rentalOwnership.share : 1;

  useEffect(() => {
    const getRentalOwnerships = async () => {
      await axios(
        {
          method: "get",
          url: `${API_URL}/costs/rentals?period=${period.toString()}`,
          headers: { Authorization: `Bearer ${localStorage.token}` },
        },
        []
      )
        .then((response) => {
          if (response && response.data) {
            setRentalOwnerships(response.data);
          }
        })
        .catch((error) =>
          error.response &&
          error.response.status &&
          error.response.status === 401
            ? logOut()
            : genericError()
        );
    };

    getRentalOwnerships();
  }, []);

  useEffect(() => {
    if (rental && period) {
      getContract(selectedRental, selectedLandlord, period);
      getSettlement(selectedRental, selectedLandlord, period);
      getFaqContent(selectedRental);
    }
  }, [rental, selectedRental, selectedLandlord, period]);

  const findMatchingRental = (rentalName, period) => {
    let matching_rental;

    const matching_rentals = rentalOwnerships.filter(
      (ro) => ro.rental.name === rentalName
    );

    if (matching_rentals.length === 0) {
      matching_rental = null;
    } else if (matching_rentals.length === 1) {
      matching_rental = matching_rentals[0];
    } else {
      matching_rental = matching_rentals
        .filter((ro) => {
          if (ro.rental.name === rentalName) {
            const start = dayjs(ro.start).hour(12);
            const end = ro.end && dayjs(ro.end).hour(12);

            const check =
              (start.year() < period.toDate().year() ||
                (start.year() === period.toDate("end").year() &&
                  start.month() <= period.toDate("end").month())) &&
              ((end && end.year() > period.toDate("start").year()) ||
                (end &&
                  end.year() === period.toDate("start").year() &&
                  end.month() >= period.toDate("start").month()) ||
                !end);

            return check;
          }

          return false;
        })
        .sort((a, b) => {
          const nameA = a.landlord.name.toUpperCase();
          const nameB = b.landlord.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })[0];
    }

    return matching_rental;
  };

  const checkIfPeriodAvaialble = (rental, period, checkMatching = true) => {
    if (!rental) return false;

    if (
      !checkRole(localStorage) &&
      ((rental &&
        rental.rental &&
        rental.rental.settlement_period !== "QUARTERLY" &&
        (period.toDate().year() < 2024 ||
          (period.toDate().year() === 2024 && period.toDate().month() < 3))) ||
        (rental &&
          rental.rental &&
          rental.rental.settlement_period === "QUARTERLY" &&
          (period.toDate().year() < 2024 ||
            (period.toDate().year() === 2024 && period.month.month === "Q1"))))
    ) {
      return false;
    }

    const start = dayjs(rental.start);
    const end = rental.end && dayjs(rental.end);

    const isAvailable =
      (start.year() < period.toDate().year() ||
        (start.year() === period.toDate("end").year() &&
          start.month() <= period.toDate("end").month())) &&
      ((end && end.year() > period.toDate("start").year()) ||
        (end &&
          end.year() === period.toDate("start").year() &&
          end.month() >= period.toDate("start").month()) ||
        !end);

    if (!isAvailable && checkMatching) {
      const matching_rental = findMatchingRental(rental.rental.name, period);
      return checkIfPeriodAvaialble(matching_rental, period, false);
    }

    return isAvailable;
  };

  const performRentalChange = (rental, landlord, period) => {
    const change = checkIfPeriodAvaialble(rental, period);

    if (change) {
      history.push(
        `/dashboard/${
          rental.rental.bookingsync_rental_id
        }/${landlord}/${period.toString()}`
      );
    } else {
      const availablePeriod = Period.fromDate(dayjs(rental.start));

      history.push(
        `/dashboard/${
          rental.rental.bookingsync_rental_id
        }/${landlord}/${availablePeriod.toString()}`
      );
    }
  };

  const selectRental = (rentalName, landlordId, period) => {
    const matching_rental = findMatchingRental(rentalName, period);

    if (
      landlordId === null ||
      matching_rental.start !== rentalOwnership.start
    ) {
      landlordId = matching_rental.landlord.id;
    }

    performRentalChange(matching_rental, landlordId, period);
  };

  async function getSettlement(bookingsync_rental_id, landlordId, period) {
    await axios(
      {
        method: "get",
        url: `${API_URL}/costs/portal?bookingsync_rental_id=${bookingsync_rental_id}&landlord_id=${landlordId}&period=${period.toString()}`,
        headers: { Authorization: `Bearer ${localStorage.token}` },
      },
      []
    )
      .then((response) => {
        setSettlement(response && response.data && response.data.settlement);
        setBookings(
          sortBy(
            response.data.bookings.data.map((booking) => {
              return {
                ...booking,
                from_date: dayjs(booking.from_date).format("YYYY-MM-DD"),
                until_date: dayjs(booking.until_date).format("YYYY-MM-DD"),
              };
            }),
            "from_date"
          )
        );
        setColumns(response.data.bookings.columns);
        setCosts(response.data.costs);
      })
      .catch((error) =>
        error.response && error.response.status && error.response.status === 401
          ? logOut()
          : genericError()
      );
  }

  async function getFaqContent(rental) {
    axios(
      {
        method: "get",
        url: `${API_URL}/costs/faq?rental=${rental}`,
        headers: { Authorization: `Bearer ${localStorage.token}` },
      },
      []
    )
      .catch((error) =>
        error.response && error.response.status && error.response.status === 401
          ? logOut()
          : genericError()
      )
      .then((response) =>
        response && response.data
          ? setFaqContent(DOMPurify.sanitize(response.data))
          : null
      );
  }

  async function getContract(bookingsync_rental_id, landlordId) {
    await axios(
      {
        method: "get",
        url: `${API_URL}/costs/rental-ownership?bookingsync_rental_id=${bookingsync_rental_id}&landlord_id=${landlordId}&period=${period.toString()}`,
        headers: { Authorization: `Bearer ${localStorage.token}` },
      },
      []
    ).then((response) => {
      if (response && response.data) {
        setContract({
          booking_accounting_method:
            response.data.rental.booking_accounting_method,
          owners_take: response.data.rental.owners_take,
          fixed_profit: response.data.rental.fixed_profit,
          daily_profit: response.data.rental.daily_profit,
          minimum_profit: response.data.rental.minimum_profit,
          commission_toggle: response.data.rental.commission_toggle,
          settlement_period: response.data.rental.settlement_period,
        });
        setLandlords(response.data.rental.landlords);
      }
    });
  }

  async function getRentalOwnership(bookingsync_rental_id) {
    await axios(
      {
        method: "get",
        url: `${API_URL}/costs/rental-ownership?bookingsync_rental_id=${bookingsync_rental_id}&period=${period.toString()}`,
        headers: { Authorization: `Bearer ${localStorage.token}` },
      },
      []
    )
      .then(
        (response) =>
          response &&
          response.data &&
          setRentalOwnerships(
            rentalOwnerships.map((rentalOwnership) => {
              if (rentalOwnership.id === response.data.id) {
                return response.data;
              }
              return rentalOwnership;
            })
          )
      )
      .catch((error) =>
        error.response && error.response.status && error.response.status === 401
          ? logOut()
          : genericError()
      );
  }

  function handleRejectionSubmit(values) {
    handleSettlementSubmit(false, values.note);
  }

  function handleApproveSubmit(values) {
    handleSettlementSubmit(true, "");
  }

  async function handleSettlementSubmit(approved, note = "") {
    setIsSettlementLoading(true);
    await axios(
      {
        method: "post",
        url: `${API_URL}/costs/accept`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
        data: {
          ownership_id: rentalOwnership && rentalOwnership.id,
          settlement_id: settlement && settlement.id,
          period: period.toString(),
          date: dayjs().format(),
          approved,
          note,
        },
      },
      []
    )
      .then((response) => {
        setSettlement(response.data);
        getRentalOwnership(rental && rental.bookingsync_rental_id);
      })
      .catch((error) =>
        error.response && error.response.status && error.response.status === 401
          ? logOut()
          : genericError()
      );
    reset({ note: "" });
    setIsSettlementLoading(false);
    setRejectNoteVisible(false);
    setInvoiceAcceptNoteVisible(false);
  }

  function renderApproveRejectButtons() {
    const isSettlementAvailable =
      settlement && settlement.approved === null && !viewOnly;
    if (selectedRental && settlement && settlement.rental === selectedRental) {
      if (settlement.approved === true) {
        return settlement.status === "Automatyczna akceptacja" ? (
          <h3>
            Zaakceptowano automatycznie zgodnie z umową najmu dnia{" "}
            {dayjs(settlement.date).format("DD/MM/YYYY")} - rozliczenie numer{" "}
            {settlement.id}
          </h3>
        ) : (
          <h3>
            Zaakceptowano dnia {dayjs(settlement.date).format("DD/MM/YYYY")} -
            rozliczenie numer {settlement.id}
          </h3>
        );
      }
      if (settlement.approved === false) {
        return (
          <>
            <h3>
              Odrzucono dnia {dayjs(settlement.date).format("DD/MM/YYYY")}
            </h3>
            <div className="reject-note-container">
              <p>
                <strong>Notatka z odrzucenia</strong>:{settlement.note}
              </p>
            </div>
            <Button
              type="primary"
              onClick={() => handleSettlementSubmit(true)}
              disabled={isSettlementLoading || viewOnly}
            >
              Zaakceptuj miesiąc
            </Button>
          </>
        );
      }
    }
    return (
      <>
        <Button
          type="primary"
          onClick={() =>
            rental && rental.self_invoicing
              ? setInvoiceAcceptNoteVisible(true)
              : handleSettlementSubmit(true)
          }
          disabled={!isSettlementAvailable || isSettlementLoading}
        >
          Zaakceptuj rozliczenie miesiąca
        </Button>
        <Button
          type="primary"
          danger
          onClick={() => setRejectNoteVisible(true)}
          disabled={!isSettlementAvailable || isSettlementLoading}
        >
          Odrzuć rozliczenie miesiąca
        </Button>
      </>
    );
  }

  function exportSettlementToPDF() {
    var element = document.getElementById("settlement-summary");
    setCompanyDataVisible(true);
    var opt = {
      margin: [0.5, 0.5],
      filename: `settlement_summary_${period}.pdf`,
      jsPDF: {
        unit: "cm",
        format: "a4",
        orientation: "landscape",
        autopaging: false,
      },
      html2canvas: { scale: 2, letterRendering: true },
      pagebreak: { mode: "avoid-all" },
    };
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => setCompanyDataVisible(false));
  }

  if (rental && !selectedLandlord) {
    history.push(
      `/dashboard/${rentalOwnership.rental.bookingsync_rental_id}/${
        rentalOwnerships.landlord.id
      }/${period.toString()}`
    );
  }

  if (rentals.length > 0 && !selectedRental) {
    if (
      rentalOwnerships &&
      rentalOwnerships.length > 0 &&
      rentalOwnerships[0] &&
      rentalOwnerships[0].rental &&
      rentalOwnerships[0].rental.settlement_period &&
      rentalOwnerships[0].rental.settlement_period === "QUARTERLY"
    ) {
      history.push(
        `/dashboard/${rentalOwnerships[0].rental.bookingsync_rental_id}/${
          rentalOwnerships[0].landlord.id
        }/${period.toQuarter()}`
      );
    } else if (!selectedLandlord) {
      history.push(
        `/dashboard/${rentalOwnerships[0].rental.bookingsync_rental_id}/${
          rentalOwnerships[0].landlord.id
        }/${period.toString()}`
      );
    } else {
      selectRental(rentals[0], rentalOwnerships[0].landlord.id, period);
    }
  }

  if (rental && !checkRole(localStorage)) {
    if (
      rental.settlement_period &&
      rental.settlement_period === "QUARTERLY" &&
      (period.toDate().year() < 2024 ||
        (period.toDate().year() === 2024 && period.month.month === "Q1"))
    ) {
      selectRental(rental.name, landlord.id, Period.fromString("2024/Q2"));
    } else if (
      rental.settlement_period &&
      rental.settlement_period !== "QUARTERLY" &&
      (period.toDate().year() < 2024 ||
        (period.toDate().year() === 2024 && period.toDate().month() < 3))
    ) {
      selectRental(rental.name, landlord.id, Period.fromString("2024/04"));
    }
  }

  return (
    <Layout
      filters={
        <div
          id="filters"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: 85,
            overflow: "auto",
          }}
        >
          <Select
            value={rental ? rental.name : ""}
            showSearch
            style={{ width: 400 }}
            optionFilterProp="children"
            onSearch={() => {}}
            onFocus={() => {}}
            onBlur={() => {}}
            onChange={(filter) => selectRental(filter, null, period)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            notFoundContent={
              <Empty
                className="empty-sign-small"
                description="Brak nieruchomości"
              />
            }
          >
            {rentals &&
              rentals.map((rentalName) => (
                <Option value={rentalName} key={rentalName}>
                  {rentalName}
                </Option>
              ))}
          </Select>
          {selectedRental && rental && landlords && (
            <Select
              value={landlord && landlord.name}
              showSearch
              style={{ width: 400 }}
              optionFilterProp="children"
              onSearch={() => {}}
              onFocus={() => {}}
              onBlur={() => {}}
              onChange={(landlordId) =>
                selectRental(rental.name, landlordId, period)
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              notFoundContent={
                <Empty
                  className="empty-sign-small"
                  description="Brak właścicieli"
                />
              }
            >
              {landlords &&
                landlords.map((landlord) => (
                  <Option value={landlord.id} key={landlord.id}>
                    {landlord.name}
                  </Option>
                ))}
            </Select>
          )}
          {selectedRental && contract && (
            <PeriodSelect
              period={period}
              onSelect={(period) =>
                selectRental(rental.name, landlord.id, period)
              }
              onCheck={(period) =>
                checkIfPeriodAvaialble(rentalOwnership, period)
              }
              contract={contract}
            />
          )}
        </div>
      }
      nav={
        checkRole(localStorage)
          ? [<div onClick={() => history.push("/apartments")}>Apartments</div>]
          : null
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 10,
          border: "1px solid #eeeeee",
          borderRadius: 10,
        }}
      >
        INFORMACJA: Szanowni Państwo, pracujemy nad udostępnieniem danych
        historycznych po aktualizacji Portalu Właściciela. Informacje będą
        dostępne w możliwie najbliższym czasie.
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
      >
        <div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Rozliczenia" key="1">
              <div id="settlement-summary">
                {selectedRental && rental && contract ? (
                  <>
                    {contract.booking_accounting_method !== "FLAT_FEE" ? (
                      <div className="table-divider">
                        <h2>
                          Rezerwacje (
                          {bookings.filter((b) => b.days > 0).length})
                        </h2>
                        <ReservationsTable
                          bookings={bookings.filter(
                            (b) =>
                              b.comments.includes(
                                "Zaliczka z anulowanej rezerwacji"
                              ) || b.days > 0
                          )}
                          columns={columns}
                        />
                      </div>
                    ) : (
                      <div className="table-divider">
                        <h2>Przychody</h2>
                      </div>
                    )}
                    <div style={{ marginBottom: 20 }}>
                      {settlement || contract ? (
                        <h4>
                          Stały czynsz miesięczny: {contract.fixed_profit} PLN
                        </h4>
                      ) : null}
                      <h4>
                        {settlement &&
                          `Suma przychodu właściciela: ${settlement.owner_income_sum} PLN`}
                      </h4>
                      {rental.vat_taxpayer && (
                        <h4>
                          {settlement &&
                            `Suma przychodu właściciela brutto: ${settlement.gross_owner_income_sum} PLN`}
                        </h4>
                      )}
                    </div>
                    <div className="table-divider">
                      <h2>Koszty ({costs.length})</h2>
                      <CostTable costs={costs} />
                    </div>
                    <div className="table-divider">
                      <h2>Rozliczenie</h2>
                    </div>
                    <div style={{ marginBottom: 20 }}>
                      <h4>
                        {settlement &&
                          `Wynik rozliczenia miesiąca: ${settlement.final_sum} PLN`}
                      </h4>
                      <h4>
                        Bilans z poprzedniego okresu:{" "}
                        {settlement
                          ? settlement.current_balance
                          : rental.current_balance}{" "}
                        PLN
                      </h4>
                    </div>
                  </>
                ) : (
                  <Empty
                    className="empty-sign"
                    description="Wybierz nieruchomość"
                  />
                )}
                <div>
                  {settlement && (
                    <Col span={12}>
                      <Statistic
                        title="Wartość przelewu zgodnie z rozliczeniem"
                        value={`${settlement.wire_transfer_amount} PLN`}
                      />
                    </Col>
                  )}
                  {rental &&
                    rental.self_invoicing &&
                    settlement &&
                    settlement.invoice_view_url &&
                    settlement.invoice_view_url !== "" && (
                      <h3>
                        <a
                          href={settlement && settlement.invoice_view_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Faktura do rozliczenia
                        </a>
                      </h3>
                    )}
                </div>
                <div>
                  {rental && rental.drafts && (
                    <>
                      {settlement && settlement.approved === null && (
                        <div>
                          <h3>
                            Rozliczenie numer {settlement.id} czeka na
                            akceptację właściciela.
                          </h3>
                        </div>
                      )}
                      {renderApproveRejectButtons()}
                    </>
                  )}
                </div>
                {rental && !rental.drafts && !settlement && (
                  <div
                    style={{
                      paddingRight: 10,
                      paddingLeft: 10,
                      backgroundColor: "#f55c47",
                      fontSize: "1rem",
                      width: 180,
                      height: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Miesiąc nierozliczony
                  </div>
                )}
                <div id="company-data">
                  {companyDataVisible && (
                    <Card
                      title="Dane firmy"
                      style={{ width: "30%", marginTop: 30 }}
                    >
                      <p>Polish Holiday Rentals sp. z o.o.</p>
                      <p>Jana Sebastiana Bacha 26A</p>
                      <p>02-743 Warszawa</p>
                      <p>NIP 6762500419</p>
                    </Card>
                  )}
                </div>
              </div>
              {selectedRental &&
                settlement &&
                settlement.rental === selectedRental &&
                settlement.approved === true && (
                  <div style={{ marginTop: 20 }}>
                    <Button onClick={exportSettlementToPDF}>
                      Pobierz podsumowanie PDF
                    </Button>
                  </div>
                )}
            </TabPane>

            {selectedRental &&
              contract &&
              contract.settlement_period &&
              contract.settlement_period != "QUARTERLY" && (
                <TabPane tab="Kalendarz" key="2">
                  <Calendar
                    bookings={bookings.filter((b) => b.days > 0)}
                    month={period.month}
                    year={period.year}
                    selected={selectedRental}
                  />
                </TabPane>
              )}
            <TabPane tab="Dane apartamentu" key="3">
              <RentalInfo rental={rental} />
            </TabPane>
            <TabPane tab="Zasady rozliczenia" key="4">
              <SettlementRules
                rental={rental}
                contract={contract}
                rentalShare={viewOnly ? 0 : rentalShare}
              />
            </TabPane>
            <TabPane tab="Często zadawane pytania" key="5">
              {faqContent ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: faqContent,
                  }}
                />
              ) : (
                <div>
                  <div>Trwają prace aktualizacyjne.</div>
                  <div>Sekcja zostanie wkrótce udostępniona.</div>
                </div>
              )}
            </TabPane>
          </Tabs>
        </div>
        <Modal
          title="Zaakceptuj rozliczenie miesiąca"
          open={invoiceAcceptNoteVisible}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setInvoiceAcceptNoteVisible(false);
              }}
            >
              Anuluj
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={isSettlementLoading}
              onClick={handleSubmit(handleApproveSubmit)}
            >
              Akceptuję rozliczenie oraz fakturę
            </Button>,
          ]}
        >
          Potwierdzam, że zapoznałam/em się oraz akceptuję podsumowanie
          rozliczenia oraz załączoną fakturę.
        </Modal>
        <Modal
          title="Odrzuć rozliczenie miesiąca"
          open={rejectNoteVisible}
          footer={[
            <Button
              key="back"
              onClick={() => {
                reset({ note: "" });
                setRejectNoteVisible(false);
              }}
            >
              Anuluj
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={isSettlementLoading}
              onClick={handleSubmit(handleRejectionSubmit)}
            >
              Prześlij
            </Button>,
          ]}
        >
          <form onSubmit={() => setRejectNoteVisible(false)}>
            <Controller
              as={<TextArea rows={4} maxLength={255} />}
              name="note"
              control={control}
              defaultValue=""
              placeholder="Proszę podać powód odrzucenia rozliczenia."
              rules={{ required: true }}
            />
            {errors.note && "Notatka jest wymagana."}
          </form>
        </Modal>
      </div>
    </Layout>
  );
}
